import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../css/AdminPage.css'; // Ensure correct path for CSS

function AdminPage() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isAdmin, setIsAdmin] = useState(false);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      if (user.role !== 'admin') {
        navigate('/login');
      } else {
        setIsAdmin(true);
        fetchTags(); // Fetch tags on component mount
      }
    } else {
      navigate('/login');
    }
  }, [token, navigate]);

  const fetchTags = async () => {
    try {
      const { data } = await axios.get('https://project-lifestyle.com/api/tags');
      setTags(data);
    } catch (err) {
      setError('Error fetching tags');
    }
  };

  const handleAddTag = async () => {
    try {
      const { data } = await axios.post(
        'https://project-lifestyle.com/api/tags',
        { name: newTag },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTags([...tags, data]);
      setNewTag('');
      setSuccess('Tag added successfully');
      setError('');
    } catch (err) {
      setError('Error adding tag');
      setSuccess('');
    }
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await axios.delete(`https://project-lifestyle.com/api/tags/${tagId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTags(tags.filter(tag => tag._id !== tagId));
      setSuccess('Tag deleted successfully');
      setError('');
    } catch (err) {
      setError('Error deleting tag');
      setSuccess('');
    }
  };

  if (!isAdmin) return null; // Don't render if not admin

  return (
    <div className="admin-page-container">
      <h2>Admin Page</h2>

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      {/* Tag Management Section */}
      <div className="tag-management">
        <h3>Manage Tags</h3>
        <input
          type="text"
          placeholder="Enter new tag"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
        />
        <button onClick={handleAddTag}>Add Tag</button>

        <div className="tags-list">
          <h4>Existing Tags:</h4>
          {tags.map(tag => (
            <div key={tag._id} className="tag-item">
              <span>{tag.name}</span>
              <button onClick={() => handleDeleteTag(tag._id)}>Delete</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
