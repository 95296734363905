import axios from 'axios';

const API_URL = 'https://project-lifestyle.com/api';

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    return response.data; // Returns the token
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const registerUser = async (name, email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, {
      name,
      email,
      password,
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      // If the error response contains a message, throw it
      throw new Error(err.response.data.message);
    } else {
      // Otherwise, throw a general error
      throw new Error('An error occurred during registration. Please try again.');
    }
  }
};

export const getCountryData = async (code) => {
  try {
    const response = await axios.get(`${API_URL}/countries/${code}`);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const upvoteEntry = async (countryCode, entryId, token) => {
  try {
    const response = await axios.post(
      `${API_URL}/countries/${countryCode}/subjective/${entryId}/upvote`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const downvoteEntry = async (countryCode, entryId, token) => {
  try {
    const response = await axios.post(
      `${API_URL}/countries/${countryCode}/subjective/${entryId}/downvote`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};
