import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Login from './components/Login';
import Register from './components/Register';
import AdminPage from './components/AdminPage';
import Profile from './components/Profile'; 
import CountryPage from './components/CountryPage';
import SubjectiveInfoPage from './components/SubjectiveInfoPage'; // Make sure this is imported
import ProtectedRoute from './components/ProtectedRoute'; 
import { jwtDecode } from 'jwt-decode';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAuthentication = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsAuthenticated(true);
        if (decoded.role === 'admin') {
          setIsAdmin(true);
        }
      } catch (err) {
        console.error('Invalid token:', err);
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <Router>
      <Navbar 
        isAuthenticated={isAuthenticated} 
        isAdmin={isAdmin} 
        onLogout={() => {
          setIsAuthenticated(false);
          setIsAdmin(false);
        }} 
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login onLogin={checkAuthentication} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={
          <ProtectedRoute isAuthenticated={isAuthenticated} role="admin">
            <AdminPage />
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Profile />
          </ProtectedRoute>
        } />
        <Route path="/country/:code" element={<CountryPage />} /> {/* For general country info */}
        <Route path="/country/:code/subjective" element={<SubjectiveInfoPage />} /> {/* This is the route you're missing */}
      </Routes>
    </Router>
  );
}

export default App;
