import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/SubjectiveInfoPage.css';
import moment from 'moment'; // For formatting "time ago"

function SubjectiveInfoPage() {
  const { code } = useParams();
  const [opinions, setOpinions] = useState([]);
  const [filteredOpinions, setFilteredOpinions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [opinion, setOpinion] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(''); // For form submission
  const [selectedFilterTag, setSelectedFilterTag] = useState(''); // For filtering
  const [userId, setUserId] = useState(null);
  const [sortOption, setSortOption] = useState('latest'); // Sorting state

  useEffect(() => {
    const fetchOpinionsAndTags = async () => {
      try {
        // Fetch subjective opinions for the specific country
        const opinionsResponse = await axios.get(`https://project-lifestyle.com/api/countries/${code}/opinions`);
        setOpinions(opinionsResponse.data || []);
        setFilteredOpinions(opinionsResponse.data || []);

        // Fetch available tags
        const tagResponse = await axios.get('https://project-lifestyle.com/api/tags');
        setTags(tagResponse.data);

        setError('');
      } catch (err) {
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    // Decode the token to extract user info
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        setUserId(payload.id);
        setIsAuthenticated(true);
      } catch (e) {
        console.error('Error decoding token', e);
        setError('Invalid token.');
        setIsAuthenticated(false);
      }
    }

    // Fetch data
    fetchOpinionsAndTags();
  }, [code, token]);

  // Function to convert timestamp to relative time using moment.js
  const timeAgo = (createdAt) => {
    return moment(createdAt).fromNow(); // Using moment.js to get time ago
  };

  // Submit a new opinion
  const handleSubmitOpinion = async () => {
    setError(''); // Clear any previous errors

    if (!opinion.trim()) {
      setError('Please enter an opinion.');
      return;
    }
    if (!selectedTag) {
      setError('Please select a tag.');
      return;
    }

    try {
      const response = await axios.post(
        `https://project-lifestyle.com/api/countries/${code}/subjective`,
        { content: opinion, tag: selectedTag },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const addedTag = tags.find(tag => tag._id === selectedTag);
      const newOpinion = {
        ...response.data,
        votedUsers: [{ user: userId, voteType: 'up' }],
        votes: 1,
        tag: addedTag,
        user: { _id: userId, name: 'You' },
      };

      setOpinions([...opinions, newOpinion]);
      setFilteredOpinions([...opinions, newOpinion]);
      setOpinion('');
      setSelectedTag('');
      setError('');
    } catch (err) {
      setError('Error adding subjective opinion.');
    }
  };

  // Vote handling
  const handleVote = async (entryId, voteType) => {
    if (!isAuthenticated) {
      setError('You must be logged in to vote.');
      return;
    }

    const opinion = opinions.find(op => op._id === entryId);
    const existingVote = opinion.votedUsers.find(vote => vote.user === userId);

    if (existingVote && existingVote.voteType === voteType) {
      // Prevent duplicate voting in the same direction
      setError('You have already voted this way.');
      return;
    }

    try {
      const response = await axios.post(
        `https://project-lifestyle.com/api/countries/${code}/subjective/${entryId}/${voteType}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Find the original opinion to maintain user and tag data
      const originalOpinion = opinions.find(op => op._id === entryId);

      const updatedOpinion = {
        ...response.data.opinion,
        user: originalOpinion?.user || response.data.opinion.user,
        tag: originalOpinion?.tag || response.data.opinion.tag,
      };

      setOpinions((prevOpinions) =>
        prevOpinions.map((opinion) =>
          opinion._id === updatedOpinion._id ? updatedOpinion : opinion
        )
      );
      setFilteredOpinions((prevFilteredOpinions) =>
        prevFilteredOpinions.map((opinion) =>
          opinion._id === updatedOpinion._id ? updatedOpinion : opinion
        )
      );
      setError('');
    } catch (err) {
      setError('Error submitting vote.');
    }
  };

  // Tag filtering
  const handleTagClick = (tagId) => {
    setSelectedFilterTag(tagId);
    filterOpinionsByTag(tagId);
  };

  const filterOpinionsByTag = (tagId) => {
    if (!tagId) {
      setFilteredOpinions(opinions);
    } else {
      const filtered = opinions.filter((entry) => entry.tag && entry.tag._id === tagId);
      setFilteredOpinions(filtered);
    }
  };

  // Handle sorting changes
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    sortOpinions(e.target.value);
  };

  // Sort opinions based on the selected sorting option
  const sortOpinions = (option) => {
    let sortedOpinions = [...filteredOpinions];
    if (option === 'latest') {
      sortedOpinions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (option === 'popular') {
      sortedOpinions.sort((a, b) => b.votedUsers.filter(v => v.voteType === 'up').length - a.votedUsers.filter(v => v.voteType === 'up').length);
    }
    setFilteredOpinions(sortedOpinions);
  };

  // Display logic
  if (loading) return <p>Loading...</p>;

  return (
    <div className="subjective-page-container">
      <h1>Subjective Information</h1>

      {isAuthenticated && (
        <div className="add-opinion-form">
          <input type="text" placeholder="Enter your opinion..." value={opinion} onChange={(e) => setOpinion(e.target.value)} />
          <select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
            <option value="">Select a tag</option>
            {tags.map((tag) => (
              <option key={tag._id} value={tag._id}>{tag.name}</option>
            ))}
          </select>
          <div className="opinion-buttons">
            <button className="do-button" onClick={handleSubmitOpinion}>Submit</button>
          </div>
          {error && <p className="error-message">{error}</p>} {/* Error message below the form */}
        </div>
      )}

      <div className="tag-filter-section">
        <button className={`tag-button ${selectedFilterTag === '' ? 'active' : ''}`} onClick={() => handleTagClick('')}>All</button>
        {tags.map((tag) => (
          <button key={tag._id} className={`tag-button ${selectedFilterTag === tag._id ? 'active' : ''}`} onClick={() => handleTagClick(tag._id)}>{tag.name}</button>
        ))}

        {/* Sort by dropdown */}
        <div className="sort-options">
          <label htmlFor="sort-select">Sort by: </label>
          <select id="sort-select" value={sortOption} onChange={handleSortChange}>
            <option value="latest">Latest</option>
            <option value="popular">Popular</option>
          </select>
        </div>
      </div>

      <div className="tiles-container">
        {filteredOpinions.length === 0 ? (
          <p>No subjective information available for the selected tag.</p>
        ) : (
          filteredOpinions.map((entry) => {
            const isOwnOpinion = entry.user && entry.user._id === userId;

            const upvotes = entry.votedUsers.filter(vote => vote.voteType === 'up').length;
            const downvotes = entry.votedUsers.filter(vote => vote.voteType === 'down').length;
            const totalVotes = upvotes + downvotes;
            const upvotePercentage = totalVotes ? (upvotes / totalVotes) * 100 : 0;
            const downvotePercentage = totalVotes ? (downvotes / totalVotes) * 100 : 0;

            // Determine button color if the user has voted
            const userVote = entry.votedUsers.find(vote => vote.user === userId);
            const upvoteButtonStyle = userVote && userVote.voteType === 'up' ? { color: '#4caf50' } : {};
            const downvoteButtonStyle = userVote && userVote.voteType === 'down' ? { color: '#ff4c4c' } : {};

            return (
              <div
                key={entry._id}
                className={`subjective-tile ${isOwnOpinion ? 'own-opinion' : ''}`}
              >
                <div className="tile-header">
                  <span className="user-name">{entry.user ? entry.user.name : 'Unknown User'}</span>
                  <span className="time-ago">{timeAgo(entry.createdAt)}</span>
                </div>
                <p>{entry.content}</p>
                <p className="tag-emoji">
                  <strong>{entry.tag ? entry.tag.emoji : '🏷️'}</strong>
                </p>

                {/* Vote count */}
                <div className="vote-count-container">
                  <span className="vote-count-text upvotes">{upvotes}</span>
                  <div className="vote-bar">
                    <div className="vote-bar-section upvotes" style={{ width: `${upvotePercentage}%` }}></div>
                    <div className="vote-bar-section downvotes" style={{ width: `${downvotePercentage}%` }}></div>
                  </div>
                  <span className="vote-count-text downvotes">{downvotes}</span>
                </div>

                {/* Voting buttons - Only display if the user is authenticated */}
                {isAuthenticated && (
                  <div className="vote-buttons-container">
                    <button
                      className="vote-button upvote-button"
                      style={upvoteButtonStyle}
                      disabled={entry.votedUsers.some(vote => vote.user === userId && vote.voteType === 'up')}
                      onClick={() => handleVote(entry._id, 'upvote')}
                    >
                      +1
                    </button>
                    <button
                      className="vote-button downvote-button"
                      style={downvoteButtonStyle}
                      disabled={entry.votedUsers.some(vote => vote.user === userId && vote.voteType === 'down')}
                      onClick={() => handleVote(entry._id, 'downvote')}
                    >
                      -1
                    </button>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default SubjectiveInfoPage;
