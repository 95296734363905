import React, { useState, useEffect, useRef } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useNavigate } from 'react-router-dom';
import '../css/HomePage.css';  // Import the CSS

const geoUrl = "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson";  // GeoJSON URL with ISO 3-letter codes

function HomePage() {
  const [hoveredCountry, setHoveredCountry] = useState('');  // Track hovered country
  const [selectedCountry, setSelectedCountry] = useState(''); // Track selected country in textbox
  const [countryData, setCountryData] = useState([]);  // List of valid countries and codes
  const [isTyping, setIsTyping] = useState(false); // Track if user is typing to control input behavior
  const [suggestedCountryCode, setSuggestedCountryCode] = useState(''); // Track the country code of the suggested country
  const inputRef = useRef(null);  // Reference to the input field
  const navigate = useNavigate();  // For navigation to country pages

  useEffect(() => {
    // Fetch countries list from GeoJSON data to use in the autocomplete feature
    fetch(geoUrl)
      .then((response) => response.json())
      .then((data) => {
        const countries = data.features.map((feature) => ({
          name: feature.properties.name,
          code: feature.id // ISO 3-letter code
        }));
        setCountryData(countries);
      });
  }, []);

  // Handle country click
  const handleCountryClick = (geo) => {
    const countryCode = geo.id;  // Use the 'id' field for country code (ISO 3-letter code)
    if (countryCode) {
      navigate(`/country/${countryCode}`);  // Navigate to the country page using the ISO code
    } else {
      console.log("Country code not available in this GeoJSON data");
    }
  };

  // Handle hover event
  const handleCountryHover = (geo) => {
    const countryName = geo.properties.name;  // Use 'name' for the country name
    if (countryName && !isTyping) {
      setHoveredCountry(countryName);  // Set hovered country name
      setSelectedCountry(countryName);  // Autofill the input box only if user is not typing
    }
  };

  // Handle when leaving hover
  const handleCountryLeave = () => {
    if (!isTyping) {
      setHoveredCountry('');  // Clear hovered country name
      setSelectedCountry('');  // Clear input box if hover is cleared
    }
  };

  // Handle textbox change
  const handleInputChange = (e) => {
    setIsTyping(true);
    const input = e.target.value;
    setSelectedCountry(input);
    autocompleteCountryName(input);
  };

  // Autocomplete country name based on input
  const autocompleteCountryName = (input) => {
    if (input.length === 0) {
      setSuggestedCountryCode(''); // Clear any suggestion if input is empty
      return;
    }

    // Find the first country that starts with the current input value
    const closestMatch = countryData.find((country) =>
      country.name.toLowerCase().startsWith(input.toLowerCase())
    );

    if (closestMatch) {
      // Auto-complete the input with the closest match
      setSelectedCountry(closestMatch.name);
      setSuggestedCountryCode(closestMatch.code);

      // Set the input field to highlight the remaining text
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(input.length, closestMatch.name.length);
        }
      }, 0);
    } else {
      setSuggestedCountryCode(''); // Clear if no match is found
    }
  };

  // Handle enter key press for country input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCountrySubmit();
    }
  };

  // Handle country submission
  const handleCountrySubmit = () => {
    // Find country code for the selected country name
    const country = countryData.find(c => c.name.toLowerCase() === selectedCountry.toLowerCase());

    if (country) {
      navigate(`/country/${country.code}`); // Navigate to the country using its code
    } else {
      console.log("Invalid country name entered");
    }
  };

  // Handle blur to reset typing state
  const handleBlur = () => {
    setIsTyping(false);
  };

  return (
    <div className="home-container">
      <div className="search-container">
        <h3 className="hover-text">Where to?</h3>
        {/* Textbox for selecting the country */}
        <input
          ref={inputRef}
          type="text"
          className="country-input"
          placeholder="Enter a country name..."
          value={selectedCountry}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
        />
      </div>

      <div className="map-container">
        <ComposableMap
          className="world-map"
          width={1200}  // Set a specific width
          height={600}  // Set a specific height
          projectionConfig={{
            scale: 200  // Adjust the scale if needed
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isSuggestedCountry = geo.id === suggestedCountryCode;

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => handleCountryClick(geo)}  // Navigate on click
                    onMouseEnter={() => handleCountryHover(geo)}  // Show country name on hover
                    onMouseLeave={handleCountryLeave}  // Clear hover on mouse leave
                    className="geography"  // Added class for custom styling
                    style={{
                      default: { fill: '#555', outline: 'none' },
                      hover: { fill: '#F53', outline: 'none' },  // Change color on hover
                      pressed: { fill: '#E42', outline: 'none' },
                      ...(isSuggestedCountry && {
                        default: { fill: '#F53', outline: 'none' }, // Use the same color as hover for the suggested country
                      }),
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
    </div>
  );
}

export default HomePage;
