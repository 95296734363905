import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Navbar.css';

function Navbar({ isAuthenticated, isAdmin, onLogout }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout(); // Update auth state in App.js
    navigate('/login'); // Redirect to the login page after logout
  };

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the homepage when "Code of Hammurabi" is clicked
  };

  return (
    <nav className="navbar">
      <div className="navbar-title" onClick={handleHomeClick}>
        <span>project </span><span className="lifestyle-highlight">lifestyle</span>
      </div>

      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {isAdmin && (
          <li>
            <Link to="/admin">Admin</Link>
          </li>
        )}
        {isAuthenticated ? (
          <>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/register">Register</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
