import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/ProfilePage.css';

function Profile() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');  // Redirect to login if not authenticated
      return;
    }

    const fetchUserData = async () => {
      try {
        const { data } = await axios.get('https://project-lifestyle.com/api/users/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(data);
        setError('');
      } catch (err) {
        setError('Failed to load user data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [token, navigate]);

  if (loading) return <p className="loading-message">Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="profile-page-container">
      <h1>Your Profile</h1>
      {user ? (
        <div className="profile-info">
          <p><strong>Username:</strong> {user.name || 'No username provided'}</p>
          <p><strong>Email:</strong> {user.email || 'No email provided'}</p>
          
          {/* Display user's opinions */}
          {user.opinions ? (
            <div className="user-opinions">
              <h2>Your Opinions</h2>
              {user.opinions.length === 0 ? (
                <p>You haven't added any opinions yet.</p>
              ) : (
                <ul>
                  {user.opinions.map((opinion) => (
                    <li className="opinion-item" key={opinion._id}>
                      <p>{opinion.content || 'No content available'}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <p>No opinions found.</p>
          )}

          {/* Display user's votes */}
          {user.votes ? (
            <div className="user-votes">
              <h2>Your Votes</h2>
              {user.votes.length === 0 ? (
                <p>You haven't voted on any opinions yet.</p>
              ) : (
                <ul>
                  {user.votes.map((vote) => (
                    <li className="vote-item" key={vote._id}>
                      <p>Voted <span className={`vote-type ${vote.voteType}`}>{vote.voteType}</span> on: {vote.opinion ? vote.opinion.content : 'Opinion not available'}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <p>No votes found.</p>
          )}
        </div>
      ) : (
        <p>No user data found.</p>
      )}
    </div>
  );
}

export default Profile;
