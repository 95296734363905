import React, { useState, useEffect } from 'react';
import { getCountryData } from '../api'; // Import the API function
import { useParams, Link } from 'react-router-dom';
import '../css/CountryPage.css'; // Import the CSS file

function CountryPage() {
  const { code } = useParams();
  const [countryData, setCountryData] = useState(null); // State to hold the country data
  const [error, setError] = useState(''); // State to hold any error messages

  // Fetch country data when the component is mounted or the `code` param changes
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        // Call the API to get country data
        const data = await getCountryData(code);
        setCountryData(data); // Set the response data to state
      } catch (err) {
        setError(err.message); // Set error message in case of failure
      }
    };
    fetchCountry(); // Call the function to fetch country data
  }, [code]);

  // Handle case where there is an error
  if (error) return <p className="error-message">{error}</p>;
  
  // Handle loading state
  if (!countryData) return <p className="loading-message">Loading...</p>;

  const { country } = countryData; // Destructure the country data from the response

  return (
    <div className="country-container">
      <h1>{country.name}</h1>

      {/* Objective Information */}
      <div className="objective-info">
        <h3>Objective Info:</h3>
        {country.objectiveInfo ? (
          <>
            <p><strong>Plugs:</strong> {country.objectiveInfo.plugs || 'Not available'}</p>
            <p><strong>Driving Side:</strong> {country.objectiveInfo.drivingSide || 'Not available'}</p>
            <p><strong>Public Transportation:</strong> {country.objectiveInfo.publicTransportation || 'Not available'}</p>
            <p><strong>Airport to City:</strong> {country.objectiveInfo.airportToCity || 'Not available'}</p>
            <p><strong>Useful Apps:</strong> {country.objectiveInfo.usefulApps?.join(', ') || 'None available'}</p>
            <p><strong>Useful Links:</strong></p>
            <ul>
              {country.objectiveInfo.links?.length > 0 ? (
                country.objectiveInfo.links.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                  </li>
                ))
              ) : (
                <p>No links available</p>
              )}
            </ul>
          </>
        ) : (
          <p>No objective information available for this country.</p>
        )}
      </div>

      {/* Button to navigate to subjective info page */}
      <div className="subjective-info">
        <Link to={`/country/${country.code}/subjective`} className="view-subjective-info-button">
          View Subjective Information
        </Link>
      </div>
    </div>
  );
}

export default CountryPage;
